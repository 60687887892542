<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Criar uma nova implantação</h3>
        <p style="text-align: left">Escolha um sistema, um cliente já cadastrado antes, um fluxo pré-definido, os stakeholders e registre o inicio de uma nova implantação.</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
    </div>
    <form class="formulario" autocomplete='off' @submit="submeter">
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="6"
          >
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'sistema')"
                :items="sistemas"
                label="Sistema"
                name="sistema"
                v-model="sistema"
                :error-messages="erros.sistema"
                outlined
        ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="6"
          >
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'cliente')"
                :items="clientes"
                label="Cliente"
                name="cliente"
                v-model="cliente"
                @change="requisitarStakeholders()"
                :error-messages="erros.cliente"
                outlined
        ></v-select>
          </v-col>
        </v-row> 
        <v-row>
          <!-- <v-col
            cols="12"
            sm="6"
            md="6"
          >
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'categoria')"
                :items="categorias"
                label="Categoria"
                name="categoria"
                v-model="categoria"
                :error-messages="erros.categoria"
                outlined
        ></v-select>
          </v-col> -->
                    <v-col
            cols="12"
            sm="12"
            md="12"
          >
              
              <v-select
                @mousedown="(e) => selecionarcampo(e, 'fluxo')"
                :items="fluxos"
                label="Fluxo"
                name="fluxo"
                v-model="fluxo"
                :error-messages="erros.fluxo"
                outlined
        ></v-select>
          </v-col> 
        </v-row>
        <v-row>

          <v-col
            cols="12"
            sm="9"
            md="9"
            class="d-flex"
          >
              <v-combobox
                @mousedown="(e) => selecionarcampo(e, 'stakeholder')"
                v-model="stakeholder"
                :items="stakeholders"
                chips
                label="Stakeholders"
                multiple
                outlined
                name="stakeholder"
                :error-messages="erros.stakeholder"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="removerStake(item)"
                  >
                    <strong>{{ item }}</strong>&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-btn 
              color="#1377f2"
              elevation="2"
              icon
              fab
              @click="criarStakeholder">
              <v-icon size="28s" color="#1377f2">mdi-plus-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <v-btn
              elevation="0"
              :ripple="false"
              class="btn"
              data-cy="btn-envio"
              color="#1377F2"
              type="submit">
              criar implantação
            </v-btn>
          </v-col>
        </v-row>     
    </form>
    <div class="modal-stakeholder-wrapper" v-if="modalstakeholder">
        <button type="button" @click="modalstakeholder=!modalstakeholder">
          <v-icon color="#F23E2C">mdi-close-circle-outline</v-icon> fechar
        </button>
        <CriarStakeholder :clienteSelecionado="cliente" v-model="novostakeholder" :retornar="true" :fechar="()=>modalstakeholder=!modalstakeholder"/>
    </div>
  </v-container>
</template>

<script>
import {alertar} from '@/services/notificacao.service.js'
import FluxoServico from '@/services/fluxo.service.js'
import ImplantacaoServico from '@/services/implantacao.service.js'
import ClienteServico from '@/services/cliente.service.js'
import StakeHServico from '@/services/stakeholder.service.js'
import SistemaServico from '@/services/sistema.service.js'
import CriarStakeholder from '@/components/Stakeholder/CriarStakeholder.vue'
export default {
  components: {CriarStakeholder},
  data(){
    return{
      sistema: null,
      sistemas: [],
      fluxo: null,
      fluxos: [],
      clientes: [],
      cliente: null,
      emails: [], 
      nomes: [], 
      categorias: [], 
      categoria: null, 
      stakeholders: [],
      stakeholder: null,
      novostakeholder: {},
      modalstakeholder: false,
      erros: {
        sistema: '',
        cliente: '',
        emails: '', 
        nomes: '', 
        categoria: '', 
        stakeholder: '',
        fluxo: '',
      },
      sistemasdb: [],
      clientesdb: [],
      stakeholdersdb: [],
      fluxosdb: [],
    }
  },
  methods:{
    async requisitarSistemas(){
      let dados = {
        "semimagem":true
      }
      this.sistemas = await SistemaServico.buscar(dados).then(res => {
        this.sistemasdb = res.data
        // return res.data.map(item => ({nome: item.nome, value: item.id}))
        return res.data.map(item => item.nome)
      })
    },
    async requisitarCategorias(){
      this.categorias = ['Categoria 1','Categoria 2', 'Categoria 3']
      
      // this.categorias = [
      //   {nome:'Categoria 1', value: 1}, 
      //   {nome:'Categoria 2', value: 2}, 
      //   {nome:'Categoria 3', value: 3}]
    },
    async requisitarClientes(){
      this.clientes = await ClienteServico.buscar().then(res => {
        this.clientesdb = res.data
        // return res.data.map(item => ({nome: item.nome, value: item.id}))
        return res.data.map(item => item.nome)
      })
    },
    async requisitarStakeholders(){
      let idcliente =  this.clientesdb.filter(el => el.nome == this.cliente)[0].id
      // this.stakeholders = await StakeHServico.buscar().then(res => 
      //   res.data.map(stake => ({nome: stake.nome, value:stake.id}))
      // )
      this.stakeholders = await StakeHServico.buscar({idcliente:idcliente}).then(res => {
        this.stakeholdersdb = res.data
        return res.data.map(stake => stake.nome)
      })
    },
    async requisitarFluxos(){
      this.fluxos = await FluxoServico.buscar().then(res => {
        this.fluxosdb = res.data
        return res.data.map(fluxo => fluxo.nome)
      })
    },
    removerStake(stake){
      const novoestado = this.stakeholder.filter(el => el !== stake)
      this.stakeholder = novoestado
    },
    selecionarcampo(e, type){
      if(this.erros[e.target.getAttribute('name')]){
        this.erros[e.target.getAttribute('name')] = null
      }
      if(type){
        this.erros[type] = null
      }
    },
    validacao(){
      let validado = true
      if(this.cliente == null){
        validado = false
        console.log('cliente')
        this.erros.cliente = 'O campo nome do cliente é obrigatório'
      }
      // if(this.nomes.length === 0){
      //   validado = false
      //   console.log('nomes')
      //   this.erros.nomes = 'Deve se preencher ao menos um nome responsável.'
      // }
      // if(this.emails.length === 0){
      //   validado = false
      //   console.log('emails')
      //   this.erros.emails = 'Deve se preencher ao menos um email responsável.'
      // }
      if(this.fluxo == null){
        validado = false
        console.log('fluxo')
        this.erros.fluxo = 'O campo fluxo é obrigatório.'
      }
      if(this.sistema == null){
        validado = false
        console.log('sistema')
        this.erros.sistema = 'O campo sistema é obrigatório.'
      }
      // if(this.categoria == null){
      //   validado = false
      //   console.log('categoria')
      //   this.erros.categoria = 'O campo categoria é obrigatório.'
      // }
      if(this.stakeholder == null){
        validado = false
        console.log('stakeholder')
        this.erros.stakeholder = 'O campo stakeholder é obrigatório.'
      }
      return validado
    },

    criarStakeholder() {
      if (this.cliente === null) {
        return alertar('warning', 'Atenção', 'O campo cliente precisa estar selecionado para criar uma stakeholder')
      }
      this.modalstakeholder=!this.modalstakeholder
    },

    async submeter(e){
      e.preventDefault()
      if(this.validacao()){
        const dadosimplantacao = {
          idsistema: this.sistemasdb.filter(el => el.nome == this.sistema)[0].id,
          idcliente: this.clientesdb.filter(el => el.nome == this.cliente)[0].id,
          urlsite: "xpto.com",
          urlapi: "xapi.com",
          idfluxo: this.fluxosdb.filter(el => el.nome == this.fluxo)[0].id,
          datainicio: null,
          dataconclusaoestimada: null,
          dataconclusao: null,
          concluida: false
        }
        await ImplantacaoServico.criar(dadosimplantacao)
          .then(res => {
            if(res.status === 200){
              this.sistema = null
              this.categoria = null
              this.fluxo = null
              this.cliente = null
              // this.emails = []
              // this.nomes = []
              //vincular stakehoders a implantacao
              this.stakeholder.map(async stake => {
                const idstake = this.stakeholdersdb.filter(el => el.nome == stake)[0].id
                 const idusuario = this.stakeholdersdb.filter(el => el.nome == stake)[0].idusuario
                 await StakeHServico.criar({id: parseInt(idstake), idimplantacao: res.data.inserted[0],idusuario: parseInt(idusuario), })
                  .then(() => {
                    console.log('sucesso vincular stakeholder')
                  })
                  .catch(() => {
                    console.log('erro em vincular stakeholder')
                  })
              })
              alertar('success', '=)', 'Implantação criada com sucesso!')
            }
          })
          .catch(e => {
            alertar('warning', '=/', 'Falha na criação da implantação')
            console.log('error na criacao de implantação', e)
          })
      }
    }

  },
  watch: {
    novostakeholder: async function(){
      await this.requisitarStakeholders()
    }
  },
  async mounted(){
    
    await this.requisitarSistemas()
    await this.requisitarCategorias()
    await this.requisitarFluxos()
    await this.requisitarClientes()
    await this.requisitarStakeholders()
  }
}
</script>

<style lang="scss" scoped>
.btn-add-stake{
  > .v-btn__content{
    flex-direction: column;
  }
}
.modal-stakeholder-wrapper{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background-color: rgb(240, 235, 248);

}
.header{
  display: flex;
  padding: 1em;
    margin: 0 auto;
  width: 60vw;
  max-width: 100%;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  margin-top: 1em;
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}
.formulario{
  margin: 0 auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
</style>
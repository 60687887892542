<template>
  <CriarImplantacao />
</template>

<script>
import CriarImplantacao from '@/components/Implantacao/CriarImplantacao'
export default {
  components: {CriarImplantacao},
  data(){
    return{}
  }
}
</script>

<style>

</style>